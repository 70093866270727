/**
 * wallop--fade.css
 *
 * @fileoverview Fade animation for wallop
 *
 * @author Pedro Duarte
 * @author http://pedroduarte.me/wallop
 */

 .Wallop--fade .Wallop-item--hidePrevious,
 .Wallop--fade .Wallop-item--hideNext {
  visibility: visible;
  -webkit-animation: fadeOut 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  -moz-animation: fadeOut 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  -ms-animation: fadeOut 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: fadeOut 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}


.buttonArrow {
  width: 80px;
    -webkit-transition: all 0.8s cubic-bezier(.7,0,.3,1);
  transition: all 0.8s cubic-bezier(.7,0,.3,1);
  margin-bottom: 20px;
}

.buttonArrow:hover {
cursor: pointer;
  -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
    -webkit-transition: all 0.8s cubic-bezier(.7,0,.3,1);
  transition: all 0.8s cubic-bezier(.7,0,.3,1);
} 

.buttonArrow.rotate:hover {

  -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);

} 


.slideNav {
  z-index: 1000;
  position: absolute;
  right: -40px;
  bottom: 20px;
}


.slideNav.left {

  left: -40px;
  bottom: 20px;
}

.buttonArrow.rotate svg {
  -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

/*
  In order to fade out properly we need to make sure
  that the item that is going to be the previous one
  has a higer z-index that the next one
 */
.Wallop--fade .Wallop-item--hidePrevious,
.Wallop--fade .Wallop-item--hideNext { z-index: 2; }
.Wallop--fade .Wallop-item--showPrevious,
.Wallop--fade .Wallop-item--showNext { z-index: 1; }

 /*==========  FADE ANIMATIONS  ==========*/
@-webkit-keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@-moz-keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@-ms-keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

